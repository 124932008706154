// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SourceIcon from '@mui/icons-material/Source';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { StyledStrong } from 'components/shared/StyledStrong';
import { pluralize } from 'utils';
import { selfId as selfIdSelector } from 'state/self/selectors';
import { useGetMyFilesIndex } from 'gql/queries';
import { useSelector } from 'react-redux';

// Component Definition
const MyFilesOverviewCard = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      history,
    },
  } = useTheme();

  const handleNavigateToMyFiles = useCallback(() => {
    navigate(`/${PATHS.MY_FILES}`);
  }, [navigate]);

  const selfId = useSelector(selfIdSelector) || '';
  const { data } = useGetMyFilesIndex(null, selfId);

  const zeroStateIcon = (
    <ZeroStateIcon
      Icon={SourceIcon}
      htmlColor={history.timelineDot}
    />
  );

  const fileCount = data?.length ?? 0;

  const content = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          You have
          {' '}
          <StyledStrong>{fileCount}</StyledStrong>
          {' '}
          {pluralize(fileCount, 'file')}
          {' '}
          shared with you.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToMyFiles}
        size="small"
        variant="outlined"
      >
        See Files
      </Button>
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="My Files"
      verticallyCenterContent
    />
  );
};

export default MyFilesOverviewCard;
