/* eslint-disable sort-keys */
// External Dependencies
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { Theme, darken, lighten } from '@mui/material/styles';
import { globalEditResources } from '@presto-assistant/api_types/api/v1/globalEdit';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { NavigateSearchFn, navigateSearch } from 'utils/lib/navigate_search';
import { TableResource, updateEditModeTable } from 'state/table/actions';
import { addNotification } from 'state/notifications/actions';
import {
  isDistrictAdmin,
  isFullAccessDfa as selectIsFullAccessDfa,
} from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { readFromDb, writeToDb } from 'utils/lib/indexed_db';
import { useDenseTable as useDenseTableSelector } from 'state/ui/quickSettingsMenu/selectors';
import { useGetOrganization } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import useDidMount from 'hooks/useDidMount';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { IToolbarAction } from '../DataTable/Toolbar';
import { SubscriberAddButtonProps } from '../SubscriberAddButton';
import { useUpdateParams } from './hooks';
import EnhancedGridToolbar, { EnhancedGridToolbarProps } from './EnhancedGridToolbar';
import TableDataGridZeroResultsState from './TableDataGridZeroResultsState';

// Local Typings
export interface TableDataGridProps {
  activeEditMode?: boolean;
  addButtonProps?: SubscriberAddButtonProps | null;
  autoHeight?: DataGridProProps['autoHeight'];
  checkboxSelection?: DataGridProProps['checkboxSelection'];
  clickRowTo?: (id: string) => string;
  columns: GridColDef[];
  components?: DataGridProProps['components'];
  componentsProps?: DataGridProProps['componentsProps'];
  customAddButton?: React.ReactNode;
  experimentalFeatures?: DataGridProProps['experimentalFeatures'];
  getRowHeight?: DataGridProProps['getRowHeight'];
  getRowId?: DataGridProProps['getRowId'];
  globalEditResource?: typeof globalEditResources[number];
  hideCheckAll?: boolean;
  hideExport?: boolean;
  hideToolbar?: boolean;
  isCellEditable?: DataGridProProps['isCellEditable'];
  isRowSelectable?: DataGridProProps['isRowSelectable'];
  leftPinnedColumns?: string[];
  loading?: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onProcessRowUpdateError?: DataGridProProps['onProcessRowUpdateError'];
  onSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  onUpdateParams?: NavigateSearchFn;
  params?: string; // Some "picker" tables send their own params
  persistColumnVisibility?: boolean;
  processRowUpdate?: DataGridProProps['processRowUpdate'];
  rows: any[] | null;
  selectionModel?: DataGridProProps['rowSelectionModel'];
  serverSide?: boolean;
  serverSideRowCount?: number;
  shouldNavigateOnSearch?: boolean;
  skipLocalDataFromIndexedDb?: boolean;
  tableResource?: TableResource;
  toolbarActions?: IToolbarAction[];
  withEditMode?: boolean;
  withSearch?: boolean;
  zeroStateMessage?: string;
}
interface StyledRootProps {
  $isEditMode: boolean;
}

// Local Variables
const getEditBackgroundColor = (theme: Theme) =>
  (theme.palette.mode === 'dark'
    ? darken(theme.palette.info.dark, 0.5)
    : lighten(theme.palette.info.light, 0.92));

const getEditBorderColor = (theme: Theme) =>
  (theme.palette.mode === 'dark'
    ? darken(theme.palette.info.main, 0.5)
    : theme.palette.info.main);

const StyledRoot = styled.div<StyledRootProps>(({
  $isEditMode,
  theme,
}) => ({
  position: 'relative',

  '.MuiDataGrid-root': {
    '& .MuiDataGrid-cell--editable': {
      // A cell that is editable will be light blue
      backgroundColor: $isEditMode
        ? getEditBackgroundColor(theme)
        : 'initial',
    },

    '& .MuiDataGrid-cell--editing': {
      '& > div': {
        height: '100%',
      },

      '& .MuiInputBase-root': {
        height: '100%',
      },
    },

    '& .MuiDataGrid-columnHeader': {
      fontWeight: 500,
    },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
      cursor: $isEditMode ? 'default' : 'pointer',
      pointerEvents: $isEditMode ? 'none' : 'initial',
    },

    // We change the colors to show the user when an error occurs during editing
    '& .Mui-error': {
      backgroundColor: theme.palette.mode === 'dark'
        ? darken(theme.palette.error.dark, 0.2)
        : lighten(theme.palette.error.light, 0.9),
      color: theme.palette.mode === 'dark'
        ? theme.palette.error.contrastText
        : theme.palette.error.dark,
    },

    border: $isEditMode
      ? `1px solid ${getEditBorderColor(theme)}`
      : 'inherit',
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
  },

  '.clickable': {
    '.MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },

  '.tableWrapper': {
    flexGrow: 1,
    height: '100%', // Needed for Safari < v15 to correctly display table data
  },

  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.paper : theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

// Avoid an 'X' for the false boolean cell. This emdash is a better visual indicator.
const BooleanCellFalseIcon = () => <> </>;

export const dataGridFilterKey = 'dataGridFilters';
export const dataGridPaginationKey = 'dataGridPagination';
export const dataGridSortKey = 'dataGridSort';

// Component Definition
const TableDataGrid = ({
  activeEditMode,
  addButtonProps,
  autoHeight,
  checkboxSelection,
  clickRowTo,
  columns,
  components,
  componentsProps,
  customAddButton,
  experimentalFeatures,
  getRowHeight,
  getRowId,
  globalEditResource,
  hideCheckAll,
  hideExport,
  hideToolbar,
  isCellEditable,
  isRowSelectable,
  loading,
  leftPinnedColumns,
  onFilter,
  onProcessRowUpdateError,
  onSelectionModelChange: onSelectionModelChangeProp,
  onUpdateParams = navigateSearch,
  params,
  persistColumnVisibility = true,
  processRowUpdate,
  rows,
  selectionModel: selectionModelProp,
  serverSide,
  serverSideRowCount,
  shouldNavigateOnSearch,
  skipLocalDataFromIndexedDb = false,
  tableResource,
  toolbarActions,
  withEditMode,
  withSearch,
  zeroStateMessage,
}: TableDataGridProps): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [localRows, setLocalRows] = useState(rows);
  const [filteredRowIds, setFilteredRowIds] = useState<GridRowId[]>([]);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleFilter = useCallback((rowIds: GridRowId[]) => {
    setFilteredRowIds(rowIds);
    onFilter?.(rowIds);
  }, [onFilter]);

  const hasLocalRows = Boolean(localRows?.length);

  const { self } = useSelfQuery();
  const { data: organizationData } = useGetOrganization();

  const isDfa = useSelector(isDistrictAdmin);
  const isFullAccessDfa = useSelector(selectIsFullAccessDfa);
  const isMobileScreen = useSelector(isMobileScreenSize);

  // Make a unique key for the indexed db based on the member id and the organization id
  const uniqueMemberIdKey = useMemo(() => {
    if (!self) {
      return '';
    }

    const baseId = self.id;

    if (isDfa) {
      return baseId;
    }

    const organizationId = organizationData?.organization?.id;

    if (isFullAccessDfa) {
      return `${baseId}_fullAccess_${organizationId}`;
    }

    return `${baseId}_${organizationId}`;
    // We only need to check the self.id instead of the entire self object
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self?.id, organizationData?.organization?.id, isDfa, isFullAccessDfa]);

  const setLocalDataFromIndexedDb = useCallback(async (rowData: any[] | null) => {
    const data = tableResource ? await readFromDb({
      encryptionKey: self?.encryptionKey ?? '',
      memberId: uniqueMemberIdKey,
      resource: tableResource,
    }) : null;

    if (data) {
      setLocalRows(data);
    } else if (rowData) {
      setLocalRows(rowData);
    }
  }, [self?.encryptionKey, tableResource, uniqueMemberIdKey]);

  const updateIndexedDb = useCallback(async (rowData: any[] | null) => {
    if (rowData && tableResource) {
      await writeToDb({
        data: rowData,
        encryptionKey: self?.encryptionKey ?? '',
        memberId: uniqueMemberIdKey,
        resource: tableResource,
      });
    }

    await setLocalDataFromIndexedDb(rowData);
  }, [self?.encryptionKey, setLocalDataFromIndexedDb, tableResource, uniqueMemberIdKey]);

  useEffect(() => {
    if (skipLocalDataFromIndexedDb) {
      setLocalRows(rows);
    } else {
      updateIndexedDb(rows);
    }
  }, [rows, skipLocalDataFromIndexedDb, updateIndexedDb]);

  const dispatch = useDispatch();

  const columnLocalStorageKey = `${tableResource}-columns`;
  const pinnedColumnsLocalStorageKey = `${tableResource}-pinned-columns`;
  const [isEditMode, setIsEditMode] = useState(activeEditMode ?? false);

  // TODO: change selector to use one of the three options standard, comfortable, or compact
  // We can update that once all tables are using the MUI DataGrid
  const useDenseTable = useSelector(useDenseTableSelector);

  const handleToggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
    dispatch(updateEditModeTable(isEditMode
      ? null
      : (tableResource as Partial<TableResource>)));
  }, [dispatch, isEditMode, tableResource]);

  // We grab the row values and pass them along to the onRowClick callback function
  const handleRowClick = useCallback(async ({ row }: GridRowParams) => {
    if (!clickRowTo) {
      return undefined;
    }

    const rowId = getRowId?.(row) ?? row.id;

    const path = clickRowTo(rowId);

    return navigate(path);
  }, [clickRowTo, getRowId, navigate]);

  const { search } = useLocation();

  const searchString = params ?? search;
  const searchParams = useParsedSearch(searchString);

  // We only care about this on mount
  const initialFilters = useMemo(
    () => {
      const dataGridFilters = searchParams[dataGridFilterKey];

      return dataGridFilters ? JSON.parse(dataGridFilters) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialPagination = useMemo(
    () => {
      const dataGridPagination = searchParams[dataGridPaginationKey];

      const defaultPagination = serverSide ? {
        page: 0,
        pageSize: 100,
      } : undefined;

      return dataGridPagination ? JSON.parse(dataGridPagination) : defaultPagination;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialSearchParam = useMemo(
    () => {
      const searchParam = searchParams.q as string | undefined;

      return searchParam ?? undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialSort = useMemo(
    () => {
      const dataGridSort = searchParams[dataGridSortKey];

      return dataGridSort ? JSON.parse(dataGridSort) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [
    gridFilterModel,
    setGridFilterModel,
  ] = useState<GridFilterModel>(initialFilters);
  const [
    gridPaginationModel,
    setGridPaginationModel,
  ] = useState<GridPaginationModel>(initialPagination);
  const [
    gridSortModel,
    setGridSortModel,
  ] = useState<GridSortModel>(initialSort);

  const didMount = useDidMount();

  useEffect(
    () => {
      if (serverSide && didMount) {
        const currentPageSize = gridPaginationModel?.pageSize;

        handleChangePagination({
          page: 0,
          pageSize: currentPageSize,
        });
      }
    },
    // exclude didMount to make sure we don't reset the pagination model on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      gridFilterModel,
      gridSortModel,
      serverSide,
    ],
  );

  const handleChangeColumnVisibility = useCallback((model: GridColumnVisibilityModel) => {
    if (!isMobileScreen && persistColumnVisibility) {
      localStorage.setItem(columnLocalStorageKey, JSON.stringify(model));
    }
  }, [columnLocalStorageKey, isMobileScreen, persistColumnVisibility]);

  const handleChangePinnedColumns = useCallback((model: GridPinnedColumns) => {
    if (isMobileScreen) {
      return;
    }

    // Don't persist the checkbox column
    const leftColumns = model?.left?.filter((column) => column !== '__check__');

    const pinnedColumnsToPersist = {
      left: leftColumns,
      right: model?.right ?? [],
    };

    if (persistColumnVisibility) {
      localStorage.setItem(
        pinnedColumnsLocalStorageKey,
        JSON.stringify(pinnedColumnsToPersist),
      );
    }
  }, [isMobileScreen, persistColumnVisibility, pinnedColumnsLocalStorageKey]);

  const initialColumns = useMemo(() => {
    const localStorageColumnsValue = localStorage.getItem(columnLocalStorageKey);

    if (localStorageColumnsValue) {
      return JSON.parse(localStorageColumnsValue);
    }

    return {};
  }, [columnLocalStorageKey]);

  const initialPinnedColumns = useMemo(() => {
    if (isMobileScreen) {
      return [];
    }

    const localStoragePinnedColumnsValue = localStorage.getItem(
      pinnedColumnsLocalStorageKey,
    );

    if (localStoragePinnedColumnsValue) {
      return JSON.parse(localStoragePinnedColumnsValue);
    }

    // For users that do not have custom pinned columns,
    //  default to the left pinned columns passed in by each table.
    if (leftPinnedColumns) {
      return {
        left: leftPinnedColumns,
        right: ['Actions'],
      };
    }

    return [];
  }, [isMobileScreen, leftPinnedColumns, pinnedColumnsLocalStorageKey]);

  const handleChangeFilters = useCallback((model: GridFilterModel) => {
    const filterModel = encodeURIComponent(JSON.stringify(model));

    setGridFilterModel(model);

    onUpdateParams(
      navigate,
      {
        [dataGridFilterKey]: filterModel,
      },
    );
  }, [navigate, onUpdateParams]);

  const handleChangePagination = useCallback((model: GridPaginationModel) => {
    const paginationModel = encodeURIComponent(JSON.stringify(model));

    setGridPaginationModel(model);

    onUpdateParams(
      navigate,
      {
        [dataGridPaginationKey]: paginationModel,
      },
    );
  }, [navigate, onUpdateParams]);

  const handleChangeSort = useCallback((model: GridSortModel) => {
    const sortModel = encodeURIComponent(JSON.stringify(model));

    setGridSortModel(model);

    onUpdateParams(
      navigate,
      {
        [dataGridSortKey]: sortModel,
      },
    );
  }, [navigate, onUpdateParams]);

  const flexColumns = useMemo<GridColDef[]>(() => {
    const localColumns = columns.map<GridColDef>((column) => ({
      flex: 1,
      minWidth: 120,
      // custom sort to allow empty values at end when asc
      sortComparator: (v1: any, v2: any) => {
        if (typeof v1 === 'undefined' && typeof v2 === 'undefined') {
          return 0;
        }

        if (!Number.isNaN(Number(v1 || 0)) && !Number.isNaN(Number(v2 || 0))) {
          return Number(v1 ?? Infinity) - Number(v2 ?? Infinity);
        }

        // Custom logic for alphanumeric values (e.g., "96B", "90L")
        const regex = /^(\d+)([A-Za-z]*)$/;
        const [, numA = 0, letterA = ''] = (v1 || '').match(regex) || [];
        const [, numB = 0, letterB = ''] = (v2 || '').match(regex) || [];

        const numComparison = Number(numA) - Number(numB);
        if (numComparison !== 0) {
          return numComparison;
        }

        // Compare the alphabetic part if the numbers are the same
        const letterComparison = letterA.localeCompare(letterB);
        if (letterComparison !== 0) {
          return letterComparison;
        }

        if (typeof v1 === 'string' || typeof v2 === 'string') {
          return (v1 as string || 'zz').localeCompare(v2 as string || 'zzz');
        }

        if (typeof v1 === 'boolean' && typeof v2 === 'boolean') {
          return Number(v1) - Number(v2);
        }

        // TODO:When we add dates to a table later, we need to handle sorting formatted date strings
        if (typeof v1 === 'object' && typeof v2 === 'object' && moment(v1).isValid() && moment(v2).isValid()) {
          return (v1 as Date).getDate() - (v2 as Date).getDate();
        }

        return 1;
      },
      ...column,
    }));

    if (checkboxSelection) {
      localColumns.unshift({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        cellClassName: 'MuiDataGrid-cellCheckbox',
        headerClassName: clsx('MuiDataGrid-columnHeaderCheckbox', isEditMode && 'Mui-disabled'),
        hideable: false,
      });
    }

    return localColumns;
  }, [columns, checkboxSelection, isEditMode]);

  // MUI will throw a console error if we don't handle DataGrid edit errors
  const handleProcessRowUpdateError = useCallback((error: Error) => {
    dispatch(addNotification(error.message, 'error'));
  }, [dispatch]);

  useUpdateParams(tableResource, searchString);

  const isRowSelectableDuringEditMode: DataGridProProps['isRowSelectable'] = useCallback(
    (isRowSelectableParams) => {
      if (isEditMode) {
        return false;
      }

      return isRowSelectable?.(isRowSelectableParams) ?? true;
    },
    [isEditMode, isRowSelectable],
  );

  const renderNoResultsOverlay = useCallback(() => {
    return <TableDataGridZeroResultsState message={zeroStateMessage ?? 'No data'} />;
  }, [zeroStateMessage]);

  const componentValues = {
    NoResultsOverlay: TableDataGridZeroResultsState,
    NoRowsOverlay: renderNoResultsOverlay,
    ...components,
    ...(!hideToolbar ? {
      Toolbar: EnhancedGridToolbar,
    } : undefined),
  };

  const initialState = useMemo(() => ({
    columns: {
      columnVisibilityModel: initialColumns,
    },
    filter: {
      filterModel: initialFilters,
    },
    pinnedColumns: {
      left: [
        GRID_CHECKBOX_SELECTION_COL_DEF.field,
        ...(initialPinnedColumns?.left ?? [])],
      right: initialPinnedColumns?.right ?? [],
    },
    sorting: {
      sortModel: initialSort,
    },
  }), [
    initialColumns,
    initialFilters,
    initialSort,
    initialPinnedColumns,
  ]);

  const [localSelectionModel, setLocalSelectionModel] = useState<DataGridProProps['rowSelectionModel']>([]);

  const selectionModel = useMemo(() => {
    return selectionModelProp ?? localSelectionModel;
  }, [localSelectionModel, selectionModelProp]);

  const handleSelectionModelChange = useCallback((newSelectionModel: DataGridProProps['rowSelectionModel']) => {
    setLocalSelectionModel(newSelectionModel);

    if (newSelectionModel) {
      onSelectionModelChangeProp?.(newSelectionModel as GridRowId[]);
    }
  }, [onSelectionModelChangeProp]);

  const componentsPropsValues = useMemo(() => ({
    toolbar: {
      addButtonProps,
      customAddButton,
      filteredIds: filteredRowIds,
      globalEditResource,
      gridFilterModel,
      gridPaginationModel,
      gridSortModel,
      hideExport,
      isEditMode,
      isHydrating: loading && hasLocalRows && !serverSide,
      onFilter: handleFilter,
      onToggleEditMode: handleToggleEditMode,
      onUpdateParams,
      rows: localRows,
      showQuickFilter: true,
      toolbarActions,
      search: initialSearchParam,
      selectedIds: selectionModel,
      shouldNavigateOnSearch,
      withEditMode,
      withSearch,
    } as EnhancedGridToolbarProps,
    ...componentsProps,
  }), [
    addButtonProps,
    componentsProps,
    customAddButton,
    filteredRowIds,
    globalEditResource,
    gridFilterModel,
    gridPaginationModel,
    gridSortModel,
    handleFilter,
    handleToggleEditMode,
    hasLocalRows,
    hideExport,
    initialSearchParam,
    isEditMode,
    loading,
    localRows,
    onUpdateParams,
    selectionModel,
    serverSide,
    shouldNavigateOnSearch,
    toolbarActions,
    withEditMode,
    withSearch,
  ]);

  const hasFilters = Boolean(
    gridFilterModel?.items.length || gridFilterModel?.quickFilterValues?.some(Boolean),
  );

  const handleStateChange = useCallback((state) => {
    if (state.pinnedColumns.right) {
      // keep the "Actions" column at the end
      const actionsIndex = (state.pinnedColumns.right as string[])
        .findIndex((column) => column === 'Actions');
      state.pinnedColumns.right.splice(actionsIndex, 1);
      state.pinnedColumns.right.push('Actions');
    }
  }, []);

  // Use DataGrid controlled mode and listen to click events to
  //  enter the edit mode with just a single click.
  // This provides better UX that triple-clicking to check a checkbox, for example.
  const handleCellClick = useCallback(
    (cellParams: GridCellParams, event: React.MouseEvent) => {
      if (!cellParams.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1
        && !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [cellParams.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[cellParams.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [cellParams.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [],
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    [],
  );

  return (
    <StyledRoot $isEditMode={isEditMode}>
      <div className="tableWrapper">
        <DataGridPro
          autoHeight={hasLocalRows && autoHeight}
          cellModesModel={cellModesModel}
          checkboxSelection={checkboxSelection}
          // If the row is clickable, use cursor pointer classname
          className={clsx(clickRowTo ? 'clickable' : '')}
          columns={flexColumns}
          density={useDenseTable ? 'compact' : 'standard'}
          disableRowSelectionOnClick={isEditMode || !clickRowTo}
          experimentalFeatures={{
            ...experimentalFeatures,
            ariaV7: true,
          }}
          filterDebounceMs={300}
          filterMode={serverSide ? 'server' : 'client'}
          filterModel={gridFilterModel}
          getRowHeight={getRowHeight}
          getRowId={getRowId}
          hideFooter={!hasLocalRows}
          initialState={initialState}
          isCellEditable={isEditMode ? isCellEditable : undefined}
          isRowSelectable={isRowSelectableDuringEditMode}
          loading={loading && !hasLocalRows && !serverSide}
          localeText={{
            toolbarExport: 'Reports',
          }}
          onCellClick={handleCellClick}
          onCellModesModelChange={handleCellModesModelChange}
          onColumnVisibilityModelChange={handleChangeColumnVisibility}
          onFilterModelChange={handleChangeFilters}
          onPaginationModelChange={handleChangePagination}
          onPinnedColumnsChange={handleChangePinnedColumns}
          onProcessRowUpdateError={onProcessRowUpdateError ?? handleProcessRowUpdateError}
          onRowClick={isEditMode ? undefined : handleRowClick}
          onRowSelectionModelChange={loading
            ? undefined
            : handleSelectionModelChange}
          onSortModelChange={handleChangeSort}
          onStateChange={handleStateChange}
          pageSizeOptions={serverSide ? [50, 100, 250, 500] : undefined}
          pagination={serverSide}
          paginationMode={serverSide ? 'server' : 'client'}
          paginationModel={gridPaginationModel}
          processRowUpdate={processRowUpdate}
          rowBuffer={isEditMode ? 75 : 10}
          rowCount={serverSideRowCount}
          rowSelectionModel={isEditMode || loading ? undefined : selectionModel}
          rowThreshold={isEditMode ? 75 : 10}
          rows={localRows ?? []}
          slotProps={{
            ...componentsPropsValues,
            toolbar: componentsPropsValues.toolbar,
            noResultsOverlay: componentsPropsValues.noResultsOverlay,
            noRowsOverlay: componentsPropsValues.noRowsOverlay,
          }}
          slots={{
            ...componentValues,
            booleanCellFalseIcon: BooleanCellFalseIcon,
            toolbar: componentValues.Toolbar,
            noResultsOverlay: hasFilters
              ? componentValues.NoResultsOverlay : componentValues.NoRowsOverlay,
            noRowsOverlay: hasFilters
              ? componentValues.NoResultsOverlay : componentValues.NoRowsOverlay,
          }}
          sortModel={gridSortModel}
          sortingMode={serverSide ? 'server' : 'client'}
          sx={hideCheckAll ? {
            // inspired by https://github.com/mui/mui-x/issues/1904#issuecomment-862827127
            '& .MuiDataGrid-columnHeaderCheckbox > div': {
              display: 'none',
            },
          } : {}}
        />
      </div>

      <Backdrop
        open={Boolean(loading && serverSide)}
        sx={{
          backgroundColor: `${theme.palette.background.paper}77`,
          position: 'absolute',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </StyledRoot>
  );
};

export default TableDataGrid;
