// External Dependencies
import {
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  useCallback,
  useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';

// Local Typings
export interface MoreActionsItem {
  action: () => void;
  icon?: ReactNode;
  isDisabled?: boolean;
  text: string;
}
interface Props {
  disabled?: boolean;
  moreActions: MoreActionsItem[];
  tooltipTitle: string;
}

// Local Variables
const anchorOrigin: MenuProps['anchorOrigin'] = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: MenuProps['transformOrigin'] = { horizontal: 'right', vertical: 'top' };

function handleKeyDown(event: KeyboardEvent) {
  if (['Enter', ' '].includes(event.key)) {
    event.stopPropagation();
  }
}

// Component Definition
const ActionsIconMenu = ({
  disabled,
  moreActions,
  tooltipTitle,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: MouseEvent, action: MoreActionsItem['action'] | null = null) => {
    event.stopPropagation();

    action?.();

    setAnchorEl(null);
  }, []);

  const ariaControls = `${tooltipTitle.toLowerCase().split(' ').join('-')}-menu`;

  return (
    <>
      <Tooltip
        arrow
        key="icon-button-more-actions"
        placement="bottom-end"
        title={tooltipTitle}
      >
        <div>
          <IconButton
            aria-controls={open ? ariaControls : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            aria-label={tooltipTitle}
            aria-owns={open ? ariaControls : undefined}
            disabled={disabled}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={ariaControls}
        onClose={(e) => handleClose(e as MouseEvent)}
        open={open}
        transformOrigin={transformOrigin}
      >
        {moreActions.map((action) => (
          <MenuItem
            disabled={action.isDisabled}
            key={action.text}
            onClick={(e) => handleClose(e, action.action)}
            onKeyDown={handleKeyDown}
          >
            {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
            {action.text && <ListItemText>{action.text}</ListItemText>}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionsIconMenu;
