// External Dependencies
import { useParams } from 'react-router-dom';
import EmailComposer from 'pages/Communication/EmailComposer';

// Component Definition
const EmailDraft = (): JSX.Element | null => {
  const { id: draftId } = useParams<{ id: string }>();

  if (!draftId) {
    return null;
  }

  return (
    <EmailComposer draftId={draftId} />
  );
};

export default EmailDraft;
