// External Dependencies
import DownloadIcon from 'mdi-material-ui/Download';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { displayFileSize } from 'utils/lib/display_file_size';
import { formatDateTime } from 'utils';
import { useGetMyFileSignedUrl, useGetMyFilesIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';
import type { DataGridColDef } from 'types/dataGrid';

// Local Typings
interface Props {
  schoolYearEnding: number | null;
  userId: string;
}

// Component Definition
const MyFilesTableUI = ({ schoolYearEnding, userId }: Props): JSX.Element => {
  const [getMySignedUrl] = useGetMyFileSignedUrl();

  const { data, isLoading } = useGetMyFilesIndex(schoolYearEnding, userId);

  const handleClickDownload = (row: GQL.IMyFile) => {
    getMySignedUrl({
      variables: {
        fileId: row.id,
        userId,
      },
    });
  };

  const uploadedByAccessor = ({ createdByName }: GQL.IMyFile) => (
    <Typography variant="body2">
      {createdByName}{createdByName === userId
        ? (
          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
          >
            {' '}(You)
          </Typography>
        )
        : ''}
    </Typography>
  );

  const columns: DataGridColDef<GQL.IMyFile>[] = [
    {
      field: 'fileName',
      flex: 1,
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Uploaded',
      renderCell: (params) => formatDateTime(params.value),
      sortable: true,
      width: 180,
    },
    {
      field: 'createdByName',
      headerName: 'Uploaded By',
      renderCell: (params) => uploadedByAccessor(params.row),
      sortable: true,
      width: 200,
    },
    {
      field: 'fileSize',
      headerName: 'Size',
      renderCell: (params) => displayFileSize(params.value),
      sortable: true,
      width: 150,
    },
  ];

  const actionsColumn = createDataGridActionsColumn<GQL.IMyFile>([
    {
      action: handleClickDownload,
      icon: <DownloadIcon />,
      text: 'Download',
    },
  ]) as DataGridColDef<GQL.IMyFile>;

  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return (
    <EnhancedCard>
      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          components={{ NoRowsOverlay: TableDataGridZeroState }}
          loading={isLoading}
          rows={data ?? []}
          tableResource="myFiles"
          withSearch
          zeroStateMessage="Files will show here when your directors share them with you."
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default MyFilesTableUI;
