// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useMyFilesSelfData } from 'hooks/useMyFilesSelfData';
import ChildSelect from 'components/shared/Selectors/ChildSelect';
import DataGridFilterCard from 'components/shared/DataGridFilterCard';
import SchoolYearDataGridFilter from 'components/shared/SchoolYearDataGridFilter';

// Local Dependencies
import MyFilesTableUI from './MyFilesTableUI';

// Local Variables
const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

// Reminder: Parents can be in a group, so we need to show their files

// Component Definition
const MyFilesTable: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsedSearch = parseSearch(search);

  const getPathOnFilterChange = useCallback((sye: number) => {
    const queryString = stringifyQueryParams({
      ...parsedSearch,
      school_year_ending: sye,
    });

    return `/${PATHS.MY_FILES}?${queryString}`;
  }, [parsedSearch]);

  // We use the "memberId" from the url's params
  //  to set the initial state for memberId
  const {
    // Filters add the schoolYearEnding param to the url for us
    schoolYearEnding,
    userId: userIdFromParams,
  } = parsedSearch;

  const selfData = useMyFilesSelfData();

  // We default the table to the current user's files
  const [userId, setUserId] = useState(selfData?.selfId || userIdFromParams);

  // If the memberId from the url has changed,
  //  we update the url params and reset the current page
  useEffect(() => {
    navigateSearch(
      navigate,
      {
        page: 1,
        userId,
      },
    );
  }, [navigate, userId]);

  // Parent user needs to check for relationships in this org
  const hasRelationships = useHasRelationships();

  if (!selfData && !userId) {
    return null;
  }

  let selectElement;

  // if parent with relationships, show the select
  //  and default to the parent's MyFiles table
  if (!selfData?.isStudent && hasRelationships) {
    selectElement = (
      <EnhancedCard sx={{ mb: 2 }}>
        <CardContent>
          <SectionSubtitle
            component="h2"
            variant="subtitle1"
          >
            Select a person and school year
          </SectionSubtitle>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={2}
          >
            <ChildSelect
              handleChangeUserId={setUserId}
              userId={userId}
            />

            <SchoolYearDataGridFilter
              fullWidth={false}
              getPathOnFilterChange={getPathOnFilterChange}
              includeAllYearsOption
              schoolYearEndingFromUrl={Number(schoolYearEnding || 0)}
            />
          </Box>
        </CardContent>
      </EnhancedCard>
    );
  } else {
    selectElement = (
      <DataGridFilterCard>
        <SchoolYearDataGridFilter
          getPathOnFilterChange={getPathOnFilterChange}
          includeAllYearsOption
          schoolYearEndingFromUrl={Number(schoolYearEnding || 0)}
        />
      </DataGridFilterCard>
    );
  }

  // if parent with no students, show MyFiles table
  // if student, show MyFiles table

  return (
    <>
      {selectElement}

      <MyFilesTableUI
        schoolYearEnding={schoolYearEnding}
        userId={userId}
      />
    </>
  );
};

export default MyFilesTable;
