// External Dependencies
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  FormActionButtons,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetSelf } from 'gql/queries';
import { useUpdateSelf } from 'gql/mutations';
import EmailSignatureForm from 'pages/Profile/EmailSignature/EmailSignatureForm';

interface FormValues {
  emailSignature: string;
}

const EmailSignature = (): JSX.Element => {
  const { data } = useGetSelf();
  const navigate = useNavigate();

  const [
    updateSelf,
    {
      loading: isSubmitting,
    },
  ] = useUpdateSelf({
    onCompleted: () => {
      navigate(`/${PATHS.PROFILE}`);
    },
  });

  const handleFormikSubmit = useCallback((
    values: FormValues,
  ) => {
    const isEmpty = values.emailSignature.replaceAll('\n', '') === '<p></p>';

    updateSelf({
      variables: {
        emailSignature: isEmpty ? '' : values.emailSignature,
      },
    });
  }, [updateSelf]);

  const handleClickCancelButton = useCallback(() => {
    navigate(`/${PATHS.PROFILE}`);
  }, [navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'Profile',
        path: `/${PATHS.PROFILE}`,
      }}
    >
      <EnhancedContainer>
        <Formik<FormValues>
          initialValues={{
            emailSignature: data?.self?.emailSignature || '',
          }}
          onSubmit={handleFormikSubmit}
        >
          {({
            handleChange,
            handleSubmit,
            values,
          }) => (
            <Form
              onSubmit={handleSubmit}
            >
              <ShowCard
                cardActions={(
                  <FormActionButtons
                    context="Email Signature"
                    isEditing={Boolean(data?.self?.emailSignature)}
                    isSubmitting={isSubmitting}
                    onClickCancelButton={handleClickCancelButton}
                  />
                  )}
                icon={EmailIcon}
                sx={{
                  overflow: 'visible',
                }}
                title="Email Signature"
              >
                <EnhancedCard sx={{ overflow: 'visible' }}>
                  <EnhancedCardContent>
                    <EmailSignatureForm
                      handleChange={handleChange}
                      values={values}
                    />
                  </EnhancedCardContent>
                </EnhancedCard>
              </ShowCard>
            </Form>
          )}
        </Formik>
      </EnhancedContainer>
    </Page>
  );
};

export default EmailSignature;
